<template>
  <b-card>
    <!-- form -->
    <validation-observer ref="formatMasterValidation">
      <b-form>
        <b-row>
          <b-col>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="secondary"
              @click="$router.go(-1)"
            >
            <feather-icon icon="SkipBackIcon" />  {{ $t("Common.Back") }}
            </b-button>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col md="6">
            <b-form-group :label="$t('Format Master List.Format Name')"> 
              <validation-provider
                #default="{ errors }"
                name="Format Name"
                rules="required"
              >
                <b-form-input
                  v-model="form.format_name"
                  :state="errors.length > 0 ? false : null"
                  type="text"
                  :placeholder="$t('Format Master List.Format Name')"
                  :disabled="!$can('write', 'masters')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider> 
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group :label="$t('Format Master List.Format Code')">
              <validation-provider
                #default="{ errors }"
                name="Format Code"
                rules="required"
              >
                <b-form-input
                  v-model="form.format_code"
                  :state="errors.length > 0 ? false : null"
                  type="text"
                  :placeholder="$t('Format Master List.Format Code')"
                  :disabled="!$can('write', 'masters')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group :label="$t('Format Master List.Format Description')" >
              <validation-provider
                #default="{ errors }"
                name="Format Description"
                rules=""
              >
                <b-form-textarea
                  v-model="form.format_desc"
                  :state="errors.length > 0 ? false : null"
                  type="text"
                  :placeholder="$t('Format Master List.Format Description')" 
                  :disabled="!$can('write', 'masters')"
                  rows="3" 
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12" class="text-center">
            <b-button
              variant="primary"
              type="submit"
              @click.prevent="saveFormat"
            >
              {{ $t("Common.Save") }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BCard,
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BCardText,
  BFormTextarea,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { required } from "@validations";
import MasterServices from "@/apiServices/MasterServices";

export default {
  name: "form",
  directives: {
    Ripple,
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BCardText,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormTextarea,
  },
  data() {
    return {
      format_id: null,
      form: {
        format_name: "",
        format_code: "",
        format_desc: "",
        format_is_active: true,
      },
      required,
    };
  },
  methods: {
    saveFormat() {
      this.$refs.formatMasterValidation.validate().then(async (success) => {
        if (success) {
          try {
            this.form.format_id = this.format_id;
            // console.log("this.form=>",this.form);return false;
            const formatMaster = await MasterServices.saveFormat(
              this.form
            );

            if (formatMaster.data.status) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title:
                    formatMaster.data.message || "Format Added Successfull",
                  icon: "EditIcon",
                  variant: "success",
                },
              });
              this.$router.replace("/masters/format-list");
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: formatMaster.data.message || "Cannot Added Format",
                  icon: "EditIcon",
                  variant: "failure",
                },
              });
            }
          } catch (error) {
            console.log("Error in saveFormat ", error);
            console.log({ error });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.data.message || "Error in saveFormat",
                icon: "EditIcon",
                variant: "failure",
              },
            });
          }
        }
      });
    },
    async getSingleFormat(format_id) {
      try {
        const response = await MasterServices.getSingleFormat(format_id);
        let singleFormat = response.data.data;
        if (response.data.status) {
          this.form = {
            format_name: singleFormat.format_name,
            format_code: singleFormat.format_code,
            format_desc: singleFormat.format_desc,
            format_is_active: singleFormat.format_is_active,
          };
        }
      } catch (err) {
        console.log("Error in getting  ", err);
      }
    },
  },
  beforeMount() {
    // console.log(this.$route.params);
    if (
      this.$route.params.format_id &&
      !isNaN(this.$route.params.format_id)
    ) {
      this.format_id = this.$route.params.format_id;
      this.getSingleFormat(this.format_id);
    }
  },
};
</script>

<style></style>
